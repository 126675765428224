import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
// import { getPractitionerName, getPractitionerRole } from '../Session/Session'

class TopNavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            practitionerName: "",
            activeRoute: 0
        };
    }

    componentDidMount() {
        // this.setState({ practitionerName: getPractitionerName() });
        this.setState({ activeRoute: this.props.activeRoute });
    }

    componentDidUpdate() {

    }

    render() {
        return (
            <Container>
                <Navbar variant="dark" expand="lg" sticky="top">
                    <Navbar.Brand href="/">
                        <img src='/logo.png' width="84" height="84" className="d-inline-block align-top" alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav>
                            <Nav.Link href="/renginiai" active={this.state.activeRoute === 1}>Renginiai</Nav.Link>
                            <Nav.Link href="/kursai" active={this.state.activeRoute === 2}>Kursai</Nav.Link>
                            <Nav.Link href="/renginiai-vaikams" active={this.state.activeRoute === 3}>Renginiai vaikams</Nav.Link>
                            <Nav.Link href="/apie" active={this.state.activeRoute === 6}>Apie</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Nav.Link href="/" style={{color: 'rgba(255,255,255,0.8)', textShadow:'2px 2px 2px black'}} active={this.state.activeRoute === 8}><FontAwesomeIcon icon={faCartShopping} /></Nav.Link>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        )
    }
}

export default TopNavBar;
