import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ContentBlock from "../Components/ContentBlock";
import Footer from "../Components/Footer";
import TopNavBar from "../Components/TopNavBar";

class RenginysPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: 'Gimtadieniai',
        }
    };

    render() {

        const pageStyle = {
            backgroundImage: "url(/renginiai-page-background.jpg)",
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };

        return (
            <Container fluid style={pageStyle}>
                <TopNavBar activeRoute={1} />
                <h1 className='page-title'>{this.state.name}</h1>
                <Container>
                    <ContentBlock>
                        <Row>
                            <Col xs={12} md={6} lg={6}>
                                <img src="/gimtadieniai.png" alt="Gimtadieniai" style={{ marginTop: '10%', width: '100%' }} />
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <div className='course-description-block'>
                                    <h2 className='course-description-title center-text'>Gimtadieniai</h2>
                                    <div>Kaip švęsti savo gimtadienį? Kokius jausmus ir mintis jums sukelia šis klausimas…

                                        Su profesionalaus virtuvės šefo-konsultanto pagalba Jūsų asmeninė šventė taps unikaliu ir nepamirštamu renginiu.
                                        Beje… kas ne mažiau svarbu, mūsų šventė Jums suteiks daug praktinės naudos ateičiai, o gal net atskleis paslėptus talentus Jumyse ir Jūsų svečiuose.</div>
                                </div>
                                <div>
                                    <h2 className='course-description-title center-text' style={{ marginTop: '10%' }}>Užklausos forma</h2>
                                    <Form>
                                        <Form.Group controlId="firstName">
                                            <Form.Label>Vardas</Form.Label>
                                            <Form.Control type="text" placeholder="Vardas" />
                                        </Form.Group>
                                        <Form.Group controlId="phone">
                                            <Form.Label>Telefonas</Form.Label>
                                            <Form.Control type="text" placeholder="Telefonas" />
                                        </Form.Group>
                                        <Form.Group controlId="email">
                                            <Form.Label>El.paštas</Form.Label>
                                            <Form.Control type="email" placeholder="El.paštas" />
                                        </Form.Group>
                                        <Form.Group controlId="message">
                                            <Form.Label>Žinutė</Form.Label>
                                            <Form.Control as="textarea" rows={3} placeholder="Žinutė" />
                                        </Form.Group>
                                        <Form.Group controlId="submit">
                                            <div style={{marginTop:'10px'}}>
                                                <Button type="submit" variant="outline-light">Siųsti</Button>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </ContentBlock>
                </Container>
                <Footer></Footer>
            </Container>
        )
    }
}

export default RenginysPage;
