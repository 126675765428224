import React from 'react';
import { Card,Row } from 'react-bootstrap';
import { Route } from 'react-router-dom';

function ContentBlock(props) {

    const title = props.title;

    return (
        <Card className='content-block'>
            <Card.Title className='center-text regular-text' style={{textTransform:'uppercase'}}>{title}</Card.Title>
            <Card.Body>
                <Row>
                    {props.children}
                </Row>
            </Card.Body>
        </Card>
    );
}

export default ContentBlock;