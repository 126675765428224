import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import TopNavBar from '../Components/TopNavBar';
import ContentBlock from '../Components/ContentBlock';
import Footer from '../Components/Footer';

class KursasPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: 'Cepelinai',
            price: '10',
            image: '/kursas1.jpg',
            level: 'Vidutinis',
            duration: '3 valandos',
            description: 'Cepelinai - tai tiesiog nuo seno žinomi virtiniai, tik jų apvalkalui vietoje miltinės buvo panaudota bulvių tešla. Šio patiekalo populiarumas Lietuvoje glaudžiai susijęs su bulvių plitimu. Tarpukariu, pradėjus eksportui auginti bekonus, bulvių auginimas tapo masiniu reiškiniu. Kartu populiarėjo ir cepelinai.',
            dishes: [
                'Cepelinai su mėsa',
                'Cepelinai su varške',
                'Kepti obuoliai su medumi'
            ],
            utencils: [
                'Prijuoste kiekvienam',
                'Vieta prie stalo su parinktais reikalingais produktais',
                'Gaminimo įrankiais ir prietaisai',
                'Visų gaminamų patiekalų receptai',
                'Kava ar arbata',
            ]
        };
    }

    componentDidMount() {

    }

    render() {

        const pageStyle = {
            backgroundImage: "url(/kursai-page-background.png)",
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };

        return (
            <Container fluid style={pageStyle}>
                <TopNavBar activeRoute={2} />
                <h1 className='page-title'>{this.state.name}</h1>
                <Container>
                    <ContentBlock>
                        <Row>
                            <Col xs={12} md={6} lg={6}>
                                <img src={this.state.image} style={{ marginTop: '10%' }}></img>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <div className='course-description-block'>
                                    <h2 className='center-text'>{this.state.name}</h2>
                                    <div>Tema: {this.state.name}</div>
                                    <div>Sunkumo lygis: {this.state.level}</div>
                                    <div>Trukmė: {this.state.duration}</div>
                                    <div>Kaina: {this.state.price}</div>
                                    <div>{this.state.description}</div>
                                    <div>Gaminamų patiekalų sąrašas:
                                        <ul>{this.state.dishes.map((dish, index) => <li key={index}>{dish}</li>)}</ul>
                                    </div>
                                    <div>Gera nuotaika - tai viskas, ką reikėtų turėti atvykstant pas mus, visu kitu - pasirūpinsime mes:
                                        <ul>{this.state.utencils.map((utencil, index) => <li key={index}>{utencil}</li>)}</ul>
                                    </div>
                                    <div><Button className='to-cart-button' variant="outline-light">Į krepšelį</Button></div>
                                </div>
                            </Col>
                        </Row>
                    </ContentBlock>
                </Container>
                <Footer></Footer>
            </Container>
        );
    }
}

export default KursasPage;