import React from 'react';
import { Card,Row } from 'react-bootstrap';
import { Route } from 'react-router-dom';

function ContentBlock(props) {

    return (
        <Card className='content-block content-block-full'>
            <Card.Body>
                <Row>
                    {props.children}
                </Row>
            </Card.Body>
        </Card>
    );
}

export default ContentBlock;