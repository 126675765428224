import React from 'react';
import { Container, Col } from 'react-bootstrap';
import TopNavBar from '../Components/TopNavBar';
import ContentBlock from '../Components/ContentBlock';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
import Renginys from '../Components/Renginys';

class RenginiaiVaikamsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageName: 'Renginiai vaikams',
            renginiaList: [
                { name: 'Gimtadieniai', image: 'gimtadieniai.png' },
                { name: 'Įmonėms', image: 'imonems.png' },
                { name: 'Išvažiuojamieji renginiai', image: 'isvaziuojamieji.png' },
                { name: 'Mergvakariai', image: 'mergvakariai.png' },
            ]
        }
    }

    render() {

        const pageStyle = {
            backgroundImage: "url(renginiai-page-background.jpg)",
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };

        return (
            <Container fluid style={pageStyle}>
                <TopNavBar activeRoute={3} />
                <Container>
                    <h1 className='page-title'>{this.state.pageName}</h1>
                    <ContentBlock>
                        {this.state.renginiaList.map((renginys, index) => {
                            var hrefString = '/renginiai/' + renginys.name;
                            return <Col key={index} xs={12} md={3} lg={3}>
                                <Link to={{ pathname: hrefString, state: { id: 100 } }} className="link-light">
                                    <Renginys Name={renginys.name} Image={renginys.image} />
                                </Link>
                            </Col>
                        })
                        }
                    </ContentBlock>
                </Container>
                <Footer></Footer>
            </Container>
        );
    }
}

export default RenginiaiVaikamsPage;