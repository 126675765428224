import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

class Footer extends React.Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col md={8} className='regular-text shadowed-text'>© 2018 Virtuvesklubas.lt Visos teisės saugomos.</Col>
                    <Col md={4} className='regular-text shadowed-text' style={{ textAlign: 'right' }}>© EPSILON PLIUS, UAB. 2022</Col>
                </Row>
            </Container>
        );
    }
}

export default Footer;