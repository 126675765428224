import React from 'react';
import { Container, Col } from 'react-bootstrap';
import ContentBlock from '../Components/ContentBlock';
import TopNavBar from '../Components/TopNavBar';
import ContentBlockSmall from '../Components/ContentBlockSmall';
import Footer from '../Components/Footer';
import Kursas from '../Components/Kursas';
import { Link } from 'react-router-dom';

class MainPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageName: 'Kursai',
            kursaiList: [
                { name: 'Cepelinai', price: '10', image: 'kursas1.jpg' },
                { name: 'Žydų virtuvė', price: '40', image: 'saksuka.jpg' },
                { name: 'Ispaniški tapas', price: '43', image: 'Gambas.jpg' },
                { name: 'Burgeriai', price: '43', image: 'Burgeris.jpeg' },
            ]
        }
    }

    render() {

        const myStyle = {
            backgroundImage: "url(/main-page-background.png)",
            width: '100vw',
            fontSize: '50px',
            backgroundSize: 'cover',
            backgroundRepeat: 'repeat',
        };

        return (
            <Container fluid style={myStyle}>
                <TopNavBar activeRoute={0} />
                <Container>
                    <div style={{ marginTop: '10%', marginBottom: '10%' }}>
                        <div className='main-page-title' style={{ marginLeft: '20%' }}>Maisto</div>
                        <div className='main-page-title' style={{ marginLeft: '30%' }}>gaminimo</div>
                        <div className='main-page-title' style={{ marginLeft: '40%' }}>kursai</div>
                    </div>
                    <ContentBlockSmall title={'Populiariasi kursai'}>
                        {this.state.kursaiList.map((kursas, index) => {
                            var hrefString = '/kursai/' + kursas.name;
                            return <Col key={index} xs={12} md={3} lg={3}>
                                <Link to={{ pathname: hrefString, state: { id: 100 } }} className="link-light">
                                    <Kursas Name={kursas.name} Price={kursas.price} Image={kursas.image} />
                                </Link>
                            </Col>
                        })
                        }
                    </ContentBlockSmall>
                </Container>
                <Footer></Footer>
            </Container>
        );
    }
}

export default MainPage;