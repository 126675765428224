import React from 'react';

class LoginPage extends React.Component {
    render() {
        return (
            <div>
                <h1>Login Page</h1>
            </div>
        );
    }
}

export default LoginPage;