import React from 'react';
import { Card } from 'react-bootstrap';

class Renginys extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Name: 'Cepelinai',
            Price: '10',
            Image: 'Renginys1.jpg',
        };
    }

    componentDidMount() {
        this.setState({ Name: this.props.Name });
        if(this.props.Image) this.setState({ Image: this.props.Image });
    }

    render() {

        const cardImgStyle = {
            borderRadius: '10px',
        }


        return (
            <Card className='item-card'>
                <Card.Img style={cardImgStyle} variant="top" src={this.state.Image} className='my-3' />
                <Card.Body>
                    <Card.Title className='center-text'>{this.state.Name}</Card.Title>
                    <Card.Text className='center-text'>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}

export default Renginys;