import React from "react";

class KursasPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "Cepelinai",
      Kaina: "10",
    };
  }

  render() {

    

    return (
      <div>
        <h1>{this.state.Name}</h1>
      </div>
    );
  }
}

export default KursasPage;