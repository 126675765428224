// import logo from './logo.svg';
import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { Container } from 'react-bootstrap';
import LoginPage from './Pages/LoginPage';
import MainPage from './Pages/MainPage';
import RenginiaiPage from './Pages/RenginiaiPage';
import KursaiPage from './Pages/KursaiPage';
import Apie from './Pages/Apie';
import RenginiaiVaikamsPage from './Pages/RenginiaiVaikamsPage';
import Cart from './Pages/CartPage';
import KursasPage from './Pages/KursasPage';
import RenginysPage from './Pages/RenginysPage';

function App() {

  const myStyle = {
    padding: '0px',
  };

  return (
    <Container fluid style={myStyle}>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/renginiai" element={<RenginiaiPage />}></Route>
          <Route path="/renginiai-vaikams" element={<RenginiaiVaikamsPage />}></Route>
          <Route path="/kursai" element={<KursaiPage />}></Route>
          <Route path="/kursai/:name" element={<KursasPage />}></Route>
          <Route path="/renginiai/:name" element={<RenginysPage />}></Route>
          <Route path="/apie" element={<Apie />}></Route>
          <Route path="/cart" element={<Cart />}></Route>
        </Routes>
      </Router>
    </Container>
  );
}

export default App;
