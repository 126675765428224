import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContentBlock from "../Components/ContentBlock";
import TopNavBar from "../Components/TopNavBar";
import { Wrapper, Status, MyMapComponent } from "@googlemaps/react-wrapper";
import Footer from "../Components/Footer";


class Apie extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageName: 'Apie',
            gmapsApiKey:'AIzaSyDUgzT5_lm3f7H0jiWoZXq7VbJheXFRT0o'
        }
    }

    render() {

        const pageStyle = {
            backgroundImage: "url(apie-page-background.png)",
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };

        const kontaktaiStyle = {
            fontFamily: 'Sitka',
            fontStyle: 'normal',
            fontWeight: '400',
            textAlign: 'center',
        };

        const kontaktaiTextStyle = {
            fontFamily: 'Sitka',
            fontStyle: 'normal',
            fontWeight: '400',
            textAlign: 'justify',
            fontSize: '24px',
        };

        return (
            <Container fluid style={pageStyle}>
                <TopNavBar activeRoute={6} />
                <Container>
                    <h1 className='page-title'>{this.state.pageName}</h1>
                    <ContentBlock>
                        <Row>
                            <Col md={6}>
                                <div style={kontaktaiStyle}>
                                    <h2>Kontaktai</h2>
                                </div>
                                <div style={kontaktaiTextStyle}>
                                    <div>Adresas: Kalvarijų g. 2, Vilnius</div>
                                    <div>Tel.: 8 656 26 937</div>
                                    <div>El. paštas: info@virtuvesklubas.lt</div>
                                </div>
                                <div>
                                    <img src="facebook.png" alt="facebook" style={{marginTop:'20px'}} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div style={kontaktaiStyle}>
                                    <h2>Kaip mus rasti?</h2>
                                </div>
                                <div className="block-shadow">
                                    <img src='Map.png' style={{width:'100%'}}></img>
                                </div>
                            </Col>
                        </Row>
                    </ContentBlock>
                </Container>
                <Footer></Footer>
            </Container>
        );
    }
}

export default Apie;