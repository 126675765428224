import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import TopNavBar from '../Components/TopNavBar';
import Kursas from '../Components/Kursas';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
import ContentBlock from '../Components/ContentBlock';

class KursaiPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageName: 'Kursai',
            kursaiList: [
                { name: 'Cepelinai', price: '10', image: 'kursas1.jpg' },
                { name: 'Žydų virtuvė', price: '40', image: 'saksuka.jpg' },
                { name: 'Ispaniški tapas', price: '43', image: 'Gambas.jpg' },
                { name: 'Burgeriai', price: '43', image: 'Burgeris.jpeg' },
                { name: 'Kinų virtuvė', price: '35', image: 'salotos.jpg' },
                { name: 'Lašiša su žolelių plutele', price: '43', image: 'zuvis.jpg' },
                { name: 'Skandinaviška virtuvė', price: '43', image: 'skandinaviska.jpg' },
                { name: 'Sicilijos virtuvė', price: '43', image: 'sicilietiska.jpg' },
            ]
        }

    }

    render() {

        const pageStyle = {
            backgroundImage: "url(kursai-page-background.png)",
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };


        return (
            <Container fluid style={pageStyle}>
                <TopNavBar activeRoute={2} />
                <Container>
                    <h1 className='page-title'>{this.state.pageName}</h1>
                    <ContentBlock>
                        {this.state.kursaiList.map((kursas, index) => {
                            var hrefString = '/kursai/' + kursas.name;
                            return <Col key={index} xs={12} md={3} lg={3}>
                                <Link to={{ pathname: hrefString, state: { id: 100 } }} className="link-light">
                                    <Kursas Name={kursas.name} Price={kursas.price} Image={kursas.image} />
                                </Link>
                            </Col>
                        })
                        }
                    </ContentBlock>
                </Container>
                <Footer></Footer>
            </Container >
        );
    }
}

export default KursaiPage;